import ScrollMagic from 'scrollmagic';
import {TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
TweenMax.defaultOverwrite = false;


export function sdgs(controller , scene){
	/*
	const sgdsTxtEfe = document.getElementById('sgdsTxtEfe');
    if(sgdsTxtEfe){
        scene.push(
            new ScrollMagic.Scene({tweenChanges: false,triggerElement: sgdsTxtEfe, duration: '200%', offset: '-600'})
                .setTween(sgdsTxtEfe, { left: '-60%' })
                .addTo(controller)
        );
    }
	*/
};