import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';

export function index(){
	const indexworks = document.getElementById('indexworks');
	if(indexworks){	
		new Swiper(indexworks , {
			spaceBetween: 0,
			centeredSlides: true,
			speed: 600,
			loop: true,
			slidesPerView:'auto',
			centeredSlides: true,
			autoplay: {
				delay: 3000,
			},
			pagination: {
				el: ".workspager",
				clickable: true,
			},
		});
	}
	
    const slice_text = document.getElementsByClassName('txt_slice');
    for(let i = 0; i < slice_text.length; i++){
        slicer(slice_text[i]);
    }
    function slicer(elem){
        const txtchange =  elem.innerHTML.replace(/<br>/g, '_');
        const txt =  txtchange;
        elem.innerHTML = '';
        txt.split('').forEach(function(c) {
            if(c == '_'){
                elem.innerHTML += '<br>';
            }
            else if(c == ' '){
                elem.innerHTML += '<span>&nbsp;</span>';
            }
            else if(c == '-'){
                elem.innerHTML += '<span class="up">'+c+'</span>';
            }
            else if(isIndent(c)){
                elem.innerHTML += '<span class="indent">'+c+'</span>';
            }
            else{
                elem.innerHTML += '<span>'+c+'</span>';
            }
        });
    }
	
    function isIndent(str){
        if(str == 'T'){
			return true;
		}else{
			return false;
		}
    };
	
	const fv = document.getElementById('firstview');
	if(fv){
		setTimeout(function(){
			const video = document.getElementById('video');
			video.play();
		},200);
		setTimeout(function(){
			fv.classList.add('active');
		},1400);
	}

};