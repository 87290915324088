export function common(){
	const bread = document.getElementById('breadcrumb');
	if(bread){
		bread.classList.add('active');
	}
	const fullSize = document.getElementsByClassName('fullSize');
	if(fullSize.length > 0){
		for(let i = 0; i < fullSize.length; i++){
			fullSize[i].style.maxHeight = window.innerHeight + 'px';
		}
	}
};