import ScrollMagic from 'scrollmagic';

import {scrolEvents} from './default';
import {index} from './index';
import {sdgs} from './sdgs';
import {common} from './common';
import {vm} from './vm';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	
    vm();
    scrolEvents(controller , scene);
    sdgs(controller , scene);
    index(controller , scene);
    common(controller , scene);
});